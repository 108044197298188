@media screen and (max-width: 1009px) {
    DIV.csc-textpic-above DIV.csc-textpic-imagewrap,
    DIV.csc-textpic-below DIV.csc-textpic-imagewrap,
    DIV.csc-textpic DIV.csc-textpic-imagewrap UL LI,
    DIV.csc-textpic DIV.csc-textpic-imagewrap DL.csc-textpic-image {
             float: none;
             display: inline-block;
             vertical-align: top;
    }
    DIV.csc-textpic-left DIV.csc-textpic-imagewrap .csc-textpic-image,
    DIV.csc-textpic-intext-left-nowrap DIV.csc-textpic-imagewrap .csc-textpic-image,
    DIV.csc-textpic-intext-left DIV.csc-textpic-imagewrap .csc-textpic-image {
             display: inline-block;
    }
    DIV.csc-textpic-imagewrap UL.csc-textpic-imagerow-none .csc-textpic-image {
             display: block;
    }
    .csc-default {clear: both;}
    /* optional */
    /* Golden Grid Layout für Bild im Text*/
    DIV.csc-textpic-intext-right-nowrap DIV.csc-textpic-imagewrap,
    DIV.csc-textpic-intext-left-nowrap DIV.csc-textpic-imagewrap,
    DIV.csc-textpic-intext-right DIV.csc-textpic-imagewrap,
    DIV.csc-textpic-intext-left DIV.csc-textpic-imagewrap {
             width: 38%; /* Bildcontainer Breite */
    }
    DIV.csc-textpic-intext-right-nowrap .csc-textpic-text,
    DIV.csc-textpic-intext-left-nowrap .csc-textpic-text {
             margin-left: 40%; /* Textblock */
    }
}

.csc-textpic-imagewrap {
    float: none !important;
    margin: 0px !important;
}
