header {
    text-align: right;
    height: 165px;
    @media @smallscreen {
        height: auto;
        .wrap {
            padding: 5px 50px;
        }
    }
    @media @phone { 
        .wrap {
            padding: 5px 25px;
        }
    }
    .logo {
        width: 246px;
        height: 112px;
        display: block;
        position: absolute;
        left: 50px;
        img {
            width: 100%;
            height: auto;
            display: block;
        }
        @media @smallscreen {
            width: 165px;
            height: auto;
        }
        @media @phone {
            width: 120px;
            left: 25px;
        }
    }
    nav {
        display: inline-block;
        position: relative;
        margin-top: 80px;
        padding-right: 25px;
        li {
            width: auto;
            display: inline-block;
            a {
                color: @w;
                text-decoration: none;
                padding: 15px;
                margin-top: 45px;
                font-size: 1.3em;
            }
            &.act {
                a {
                    text-decoration: underline;
                }
            }
        }
        .hovicon {
            display: none;
            cursor: pointer;
        }
        @media @smallscreen {
            margin-top: 0px;
            width: 100%;
            .hovicon {
                display: block;
                height: 80px;
                padding: 50px 5px 10px 5px;
                font-size: 1.5em;
            }
            ul {
                display: none;
                width: 100%;
                padding-top: 15px;
            }
            li {
                display: block;
                width: 100%;
                a {
                    .box-sizing(border-box);
                    display: block;
                    margin-top: 0px;
                    text-align: left;
                    border-bottom: 1px solid #5f1625;
                    font-size: 1.2em;
                    &:hover {
                        text-decoration: none;
                        background: #5f1625;
                    }
                }
                .fb {
                    border-bottom: 0px solid #5f1625;
                }
            }
            &:hover {
                ul {
                    display: block;
                }
            }
        }
        @media @phone {
            padding-right: 0px;
            .hovicon {
                display: block;
                height: 55px;
                padding: 34px 5px 10px 5px;
                font-size: 1.2em;
            }
        }
    }
}