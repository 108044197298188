// IMPORT WEBFONT if you need to
@import (inline) '../css/font-awesome.min.css';
@import (inline) '../css/unslider.min.css';
@import 'modules/_reset.less';
@import 'modules/_resetcsc.less';
@import 'modules/_base.less';
@import 'modules/_header.less';
@import 'modules/_footer.less';
/******
 * BASE ELEMENTS & UTILITIES *
 ******/
* {
  .box-sizing(border-box);
}

html, body {
  position: relative;
  display: block;
  width: 100%;
  height: 100%;
  min-height: 100%;
  margin: 0;
  font-size: 18px;
  overflow-x: hidden; 
  font-family: 'Amiri', serif;  
}
a {
  color: @red;
  text-decoration: none;
  &:hover {
    text-decoration: underline;
  }
}
h1 {
  padding: .5em 50px .2em 50px;
  font-size: 4em;
  line-height: .7em;
  font-weight: 700;
  color: @red;
  width: auto;
  max-width: @inwidth;
  display: block;
  position: relative;
  margin: 0px auto;
  .small {
    font-size: .5em;
  }
  @media @smallscreen {
    font-size: 3em;
  }
  @media @tablet {
    font-size: 2em;
  }
  @media @phone {
    font-size: 1.5em;
    padding: .5em 25px .2em 25px;
  }
  @media @iphone {
    font-size: 1em;
    padding: .5em 25px .2em 25px;
  }
}
h2 { 
  font-size: 1.5em;
  line-height: 1.2em;
  font-weight: 700;
  margin-bottom: 5px;
}
h3, h4 {
  font-size: 1.2em;
  line-height: 1.2em;
  font-weight: 700;
  margin-bottom: 25px;
}
header {
  background: @red;
  color: @w;
}
.wrap {
  width: auto;
  max-width: @inwidth;
  display: block;
  position: relative;
  margin: 0px auto;
  padding: 25px;
  @media @phone {
    padding: 25px 0px;
  }
}
.col--6 {
  width: 50%;
  display: inline-block;
  vertical-align: top;
  &.col__left {
    padding-right: 30px;
  }
  &.col__right {
    padding-left: 30px;
  }
  @media @phone {
    width: 100%;
    &.col__left {
      padding-right: 0px;
    }
    &.col__right {
      padding-top: 25px;
      padding-left: 0px;
    }
  }
}
.col--4 {
  width: 33.33%;
  display: inline-block;
  vertical-align: top;
  @media @phone {
    width: 100%;
  }
}
picture {
  width: 100%;
  height: auto;
  display: block;
  position: relative;
  img {
    width: 100%;
    height: auto;
    display: block;
  }
}
.figure, .figure picture, .figure img, .figure a {
  width: 100%;
  display: block;
  height: auto;
  position: relative;
}
.figure picture{
  .transition(all .4s ease-in-out );
}
.figure {
  overflow: hidden;
  background: @red;
  &:hover {
    picture {
      .transition(all .4s ease-in-out );
      opacity: .7;
      .transform(scale(1.2));
    }
  }
}
.figure .figcaption {
  background: @w;
  position: absolute;
  bottom: 15px;
  right: 0px;
  padding: 10px 15px;
  max-width: 90%;
  font-style: italic;
}
.small-line {
  width: 100%;
  height: 15px;
  background: @red;
  margin-bottom: 20px;
}
.article {
  background: @w;
  width: auto;
  max-width: @inwidth;
  display: block;
  position: relative;
  margin: 0px auto;
  padding: 25px 50px;
  @media @phone {
    padding: 25px;
  }
}
.start {
  .wrap {
    margin-top: -150px;
    @media @smallscreen {
      margin-top: -50px;
    }
    @media @phone {
      padding-top: 0px;
      margin-top: -0px;
    }
    .article {
      padding: 25px;
    }
  }
}
.article {
  h2, h3, h4 {
    margin-bottom: 15px;
  }
}
p {
  line-height: 1.3em;
}
img {
  width: 100%; 
  height: auto;
}
strong {
  font-weight: bold;
}
.line {
  min-height: 140px;
  font-size: 2.7em;
  color: white;
  background: @red;
  padding: 40px 25px;
  text-align: center;
  @media @tablet {
    padding: 25px 0px;
  }
  h1, h2, h3, h4 {
    font-size: 1em;
    padding: 0px 25px;
    display: block;
    margin: 0px auto;
    max-width: @inwidth;
    text-align: center;
  }
  @media @smallscreen {
    font-size: 2.2em;
  }
  @media @tablet {
    font-size: 1.6em;
  }
}
.main .ce-bodytext{
  ul {
    list-style-type: disc;
    margin-left: 20px;
    line-height: 1.4em;
  }
}
.font {
  &.font--small {
    font-size: .8em;
    line-height: 1.2em;
  }
  &.font--normal {
    font-size: 1.2em;
    line-height: 1.2em;
  }
  &.font--large {
    font-size: 1.4em;
    line-height: 1.2em;
  }
}

[data-ce-images="2"], [data-ce-images="3"] {
  margin-bottom: 20px;
  .ce-row {
    .flexbox();
    justify-content: space-between;
  }
  .ce-column {
    padding: 0 5px;
    &:first-child {
      padding-left: 0;
      padding-right: 10px;
    }
    &:last-child {
      padding-left: 10px;
      padding-right: 0;
    }
  }
}
.gallery_column[data-column="3"] { 
  .gallery__element {
    width: 33%; 
    display: inline-block;
    vertical-align: top;
    .figure {
      background: transparent;
      &:hover {
        picture {
          .transition(all .4s ease-in-out );
          opacity: 1;
          .transform(scale(1));
        }
      }
    }
  }
}