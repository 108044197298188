footer {
  width: 100%;
  .wrap {
    position: relative;
    display: block;
    margin: 0px auto;
    max-width: @inwidth;
  }
  
    .logo {
        padding: 0px 25px;
        display: inline-block;
        img {
            width: 160px;
            height: auto;
            display: block;
            @media @phone {
                width: 80px;    
            }
        }
        
    }
    .txt {
      width: auto;
      display: inline-block;
      vertical-align: top;
      position: relative;
      @media @phone {
        display: block;  
      }
      >div {
        padding: 55px 25px 25px 25px;
        width: auto;
        display: inline-block;
        vertical-align: top;
        position: relative;
      }
      @media @smallscreen {
        >div {
            display: block;
            padding: 25px;
            &:nth-child(2) {
                padding-top: 0px;
            }
        }
      }        
    }
    nav {
        position: absolute;
        right: 50px;
        top: 100px;
        @media @smallscreen {
            position: relative;
            right: 0px;
            top: 0px;
        }
        @media @phone {
            position: relative;
            padding: 25px;
        }
        .nav__footer li {
            display: inline-block;
            margin-left: 10px;
            &:first-child {
                margin-left: 0;
            }
        }
    }
    .medienmonster {
        position: absolute;
        right: 50px;
        bottom: 25px;
        // padding-top: 25px;
        max-width: 180px;
        font-size: .78em;
        a {
            position: relative;
            display: block;
            text-align: right;
            img {
                display: inline-block;
                margin-top: 5px;
            }
        }
        @media @phone {
            position: relative;
            right: 0px;
            bottom: 0px;
            padding: 25px;
            text-align: left;
            a {
                text-align: left;
            }
        }
        @media @iphone {
            position: relative;
            right: 0px;
            bottom: 0px;
            padding: 25px;
            text-align: left;
            a {
                text-align: left;
            }
            font-size: .8em;
        }
    }
}
